<template>
  <div>
    <h1>Ajouter un contrat</h1>
    <form-contrat :ismeddetail="ismeddetail" ref="formCont" />
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormContrat from "@/components/rh/ui/form/FormContrat";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-contrat": FormContrat },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("contl/resetContrat", idMed);
  },
  computed: {
    contratCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.contl.contratCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formCont.$refs.formContrat.validate((valid) => {
        if (valid) {
          var contdata = this.$store.state.contl.contrat;

          // enlever les espaces avant et après dans les string
          trimStringInObj(contdata);

          // vérifie si un contrat identique existe déjà
          var ContsInBD = this.$store.getters["contl/ContsInBD"];
          if (
            ContsInBD.filter(
              (m) => m[1] == contdata.id_med && m[2] == contdata.debut_date
            ).length > 0
          ) {
            this.$alert(
              "Ce médecin possède déjà un contrat débutant le " +
                contdata.debut_date,
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur maj l'enregistrement
            this.$store.dispatch("contl/createContrat", contdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des contrats
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    contratCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.contratCreateStatus == 2) {
        // message succès
        this.$message({
          message: "Le contrat a été ajouté-e à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du contrat
        this.$router.push(this.routes.toedit);
      } else if (this.contratCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.contratCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.contratCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
