<template>
  <el-main class="rh-form">
    <form-contratcreate :routes="contRoutes" />
  </el-main>
</template>
<script>
import FormContratcreate from "@/components/rh/formulaires/FormContratcreate";

export default {
  components: { "form-contratcreate": FormContratcreate },
  computed: {
    contRoutes() {
      return {
        toedit: {
          name: "contratedit",
          params: { idcont: this.$store.state.contl.contratLastCreatedId },
        },
        tolist: { name: "contrats" },
      };
    },
  },
};
</script>
